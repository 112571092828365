<template>
	<div class="w-full custom-number-input">
		<div class="relative flex items-center justify-between w-full mt-1 bg-transparent rounded-lg">
			<div>
				<button type="button" class="counterBtn" :disabled="modelValue <= 1" @click="decrement">
					<span class="m-auto text-2xl font-thin">−</span>
				</button>
			</div>

			<input :value="modelValue" type="number" name="custom-input-number" @input="emitValue(Number(($event.target).value))"
        class="w-full font-semibold text-center bg-transparent outline-none text-md md:text-basecursor-default border-none" 
      />
			<div>
				<button type="button" class="counterBtn" @click="increment">
					<span class="m-auto text-2xl font-thin">+</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import {ref, watch, defineProps, defineEmits} from 'vue'
const emits = defineEmits(['update:modelValue', 'change'])
const props = defineProps({
  modelValue: {
    type: Number,
    required: false,
    default: 0
  }
})
const debounce = ref(null)
const countedNumber = ref(1)

// watch(() => props.modelValue, (val) => {
//   if (val) {
//     countedNumber.value = val
//   } else {
//     countedNumber.value = 1
//   }
// }, { immediate: true })

const emitValue = (num) => {
  clearTimeout(debounce.value)
  debounce.value = setTimeout(() => {
    emits('change', num)
  }, 700)
}

const increment = () => {
  emits('change', props.modelValue + 1)
}
const decrement = () => {
  if (props.modelValue > 0) {
    emits('change', props.modelValue - 1)
  }
}
</script>

<style>
h1, h2, h3, h4, h5, h6, p, span{
	margin: 0 !important;
}

/* .counterBtn {
  @apply bg-black text-white h-8 w-8 rounded-full cursor-pointer outline-none disabled:bg-gray6
} */

.counterBtn{
  background: black;
  color: white;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled{
    background-color: #B9BCC8;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}
</style>
